.customer-block {
  background: #fff;
  padding: 32px;
  max-width: 640px;
  margin: 48px 0 0 0;
  text-align: center;
  border-radius: 12px;

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 30px;
    font-weight: 300;
  }

  .section_content {
    h3 {
      font-weight: bold;
    }
  }

  p {
    font-size: 16px;
    margin: 0 0 8px 0;
    color: rgba(118, 118, 118, 1);

    a {
      color: rgba(118, 118, 118, 1);
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .btn {
    margin: 0 !important;
  }

  span {
    font-size: 14px;
  }

  .section_heading {
    margin: 0 0 24px 0;

    .sp-icon {
      width: 100px;
      height: 100px;
    }
  }

  .section_content {
    margin: 0;
  }

  .search_bar {
    display: inline-block;
    margin: 0 0 24px 0;
    position: relative;

    .customer-dropdown {
      width: 100%;
      padding: 20px 24px;

      p {
        font-size: 10px;
        text-transform: uppercase;
        color: rgba(118, 118, 118, 1);
        margin: 0;
      }

      .search-info {
        position: relative;

        .results {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 10px;
          color: rgba(118, 118, 118, 1);
        }
      }

      .search-results {
        max-height: 384px;
        overflow-y: scroll;

        p {
          margin: 0 0 8px 0;
        }
      }

      .search-result {
        display: inline-block;
        width: 100%;
        margin: 0 0 8px 0;

        img {
          width: 40px;
          height: 40px;
          float: left;
          margin: 0 8px 0 0;
          border: 1px solid #EAEAEA;
          border-radius: 50%;
        }

        .search-content {
          float: left;
          width: calc(100% - 48px);

          h3 {
            font-size: 15px;
            margin: 0;
            line-height: 17px;
            font-weight: normal;
          }

          p {
            font-size: 12px;
            text-transform: capitalize;
            margin: 0;
          }
        }
      }
    }
  }
}
