#selectSubjectModal {
  #accounting {
    background-image: url('../img/subjects/accounting.png');
  }

  #agricultural-sciences {
    background-image: url('../img/subjects/agricultural-sciences.png');
  }

  #business-studies {
    background-image: url('../img/subjects/business-studies.png');
  }

  #civil-technology {
    background-image: url('../img/subjects/civil-technology.png');
  }

  #consumer-studies {
    background-image: url('../img/subjects/consumer-studies.png');
  }

  #creative-arts {
    background-image: url('../img/subjects/creative-arts.png');
  }

  #design {
    background-image: url('../img/subjects/design.png');
  }

  #dramatic-arts {
    background-image: url('../img/subjects/dramatic-arts.png');
  }

  #economic-and-management-sciences {
    background-image: url('../img/subjects/economic-and-management-sciences.png');
  }

  #economics {
    background-image: url('../img/subjects/economics.png');
  }

  #electrical-technology {
    background-image: url('../img/subjects/electrical-technology.png');
  }

  #engineering-graphics-and-design {
    background-image: url('../img/subjects/engineering-graphics-and-design.png');
  }

  #geography {
    background-image: url('../img/subjects/geography.png');
  }

  #history {
    background-image: url('../img/subjects/history.png');
  }

  #hospitality-studies {
    background-image: url('../img/subjects/hospitality-studies.png');
  }

  #information-technology {
    background-image: url('../img/subjects/information-technology.png');
  }

  #language {
    background-image: url('../img/subjects/language.png');
  }

  #life-orientation {
    background-image: url('../img/subjects/life-orientation.png');
  }

  #life-sciences {
    background-image: url('../img/subjects/life-sciences.png');
  }

  #life-skills {
    background-image: url('../img/subjects/life-skills.png');
  }

  #mathematical-literacy {
    background-image: url('../img/subjects/mathematical-literacy.png');
  }

  #mathematics {
    background-image: url('../img/subjects/mathematics.png');
  }

  #mechanical-technology {
    background-image: url('../img/subjects/mechanical-technology.png');
  }

  #natural-sciences {
    background-image: url('../img/subjects/natural-sciences.png');
  }

  #natural-sciences-and-technology {
    background-image: url('../img/subjects/natural-sciences-and-technology.png');
  }

  #physical-sciences {
    background-image: url('../img/subjects/physical-sciences.png');
  }

  #religion-studies {
    background-image: url('../img/subjects/religion-studies.png');
  }

  #social-sciences {
    background-image: url('../img/subjects/social-sciences.png');
  }

  #technical-mathematics {
    background-image: url('../img/subjects/technical-mathematics.png');
  }

  #technical-science {
    background-image: url('../img/subjects/technical-science.png');
  }

  #technology {
    background-image: url('../img/subjects/technology.png');
  }

  #tourism {
    background-image: url('../img/subjects/tourism.png');
  }

  #visual-arts {
    background-image: url('../img/subjects/visual-arts.png');
  }

  #test {
    background-image: url('../img/subjects/test.png');
  }
}
