/* ------------ */
/* -- Common -- */
/* ------------ */

html, body {
    height: 100%;
}

body {
    color: #4A4A4A;
    background: #fff;
    display: flex;
    flex-direction: column;
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1270px;
    }

    .landing .container, .landing .container-sm, .landing .container-md, .landing .container-lg, .landing .container-xl {
        max-width: 1100px;
    }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 300;
}

p:last-child {
    margin-bottom: 0;
}

a:hover {
    color: #cee279;
    text-decoration: none;
}

.block_404 {
    text-align: center;
}

.return_home_button {
    display: inline-block;
    margin: 20px 0 0 0;
}

.white_text {
    color: #fff;
}

.btn-snapplify.btn-dark {
    background-color: #6C963F;
    border-color: #6C963F;

    &:hover,
    &:focus,
    &:active,
    &.active {
        background-color: #b7c73b;
        border-color: #b7c73b;
    }
}

/* ------------ */
/* ------------ */
/* ------------ */

/* ------------ */
/* -- Header -- */
/* ------------ */

/* ------------- */
/* -- Country -- */
/* ------------- */

.country_bar {
    background: #F3F3F3;
    padding: 22px 0;
    border-bottom: 0.5px solid #9B9B9B;
}

.country_bar p {
    font-size: 12px;
    text-align: center;
    margin: 0 0 10px 0;
}

@media (min-width: 768px) {
    .country_bar p {
        font-size: 13px;
        margin: 0;
    }
}

@media (min-width: 992px) {
    .country_bar p {
        font-size: 15px;
    }
}

@media (min-width: 1200px) {
    .country_bar p {
        font-size: 17px;
    }

    .country_bar .col-container {
        display: flex;
    }
}

@media (max-width: 1199px) {
    .country_bar .btn {
        margin: 10px 0 0 0;
        width: 100%;
    }
}

.country_bar select::-ms-expand {
    display: none;
}

/* ------------- */
/* ------------- */
/* ------------- */

/* ----------- */
/* -- Login -- */
/* ----------- */

.login_bar p {
    text-align: center;
    font-size: 14px;
}

@media (min-width: 768px) {
    .login_bar p {
        text-align: right;
        float: right;
        margin: 0 10px 0 0;
    }
}

.login_bar a {
    font-weight: bold;
    color: #4A4A4A;
    text-decoration: none;
}

.login_bar .logged-in {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
}

/* ----------- */
/* ----------- */
/* ----------- */

/* ------------ */
/* ------------ */
/* ------------ */

/* ------------- */
/* -- Content -- */
/* ------------- */

.content {
    background: #fff url('../img/standards/city_bg.svg') no-repeat fixed 0 35%;
    flex: 1;
    padding-top: 30px;
    padding-bottom: 30px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.content .container {
    position: relative;
    background: #FFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.content .container-contained {
    max-width: 720px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.content .container-contained .inner_content {
    text-align: center;
}

.content .inner_content {
    padding: 30px 15px;
    float: left;
    width: 100%;
}

.content ul {
    list-style: disc !important;
    margin-left: 20px;
    padding: 0;
}

.content .inner_content .section_content ul {
    list-style: none !important;
    margin-left: 0;
    padding: 0;
}

.content .container-contained .section_buttons .btn {
    width: 100%;
    margin: 0 0 20px 0;
}

.content .container-contained .section_buttons .btn:last-of-type {
    margin: 0;
}

.section_heading, .section_content {
    float: left;
    width: 100%;
    margin: 0 0 31px 0;
}

.section_footer {
    padding: 15px 30px;
    background-color: #f8f8f8;
    text-align: center;
}

.section_footer p {
    font-size: 14px;
}

@media (min-width: 992px) {
    div.show-image {
        width: 100%;
    }
}

section.freeaccess {
    margin: 50px 0 0 0;
}

section.bookbundle {
    margin: 50px 0 0 0;
}

.asset-info {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.product-title-container {
    display: flex;
}

.product-title-text {
    flex: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    min-height: 3em;
}

.product-title-text a {
    color: #000;
}

.product-title-menu-link {
    display: block;
    padding-left: 1em;
    font-size: 1.2rem;
    color: #666;
    cursor: pointer;
}

.asset-grid .product .show-image {
    border: none;
    background-size: contain;
}

.asset-grid .product .show-image.loaded {
    background-image: none;
    background-color: transparent;
    background-position: top;
    background-size: contain;
}

.asset-grid .product .show-image:hover,
.asset-grid .product .show-image:focus {
    outline: none;
    box-shadow: none;
}

.asset-grid .product-title-text, .product-grid .product-title-text a {
    font-size: 15px;
    font-weight: bold;
    color: #333;
}

.asset-grid small {
    font-family: "Roboto";
    font-weight: 300;
    font-size: 11px;
    letter-spacing: 0.05em;
    color: #9e9e9e !important;
}

.asset-grid .price, .asset-grid .price price {
    font-size: 22px !important;
    color: #333 !important;
    font-weight: 900;
}

.asset-grid .fav-star {
    position: absolute;
    background: none;
    top: 0;
}

.asset-grid .price price {
    font-size: 110%;
    color: #6c963f
}

div.show-image {
    cursor: pointer;
    display: block;
    position: relative;
    align-items: center;
    justify-content: center;
}

div.show-image img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
}

div.show-image img.no_image {
    width: 100%;
    height: auto;
}

.action-toolbar a.btn, .action-toolbar button.btn {
    margin-bottom: 0.9em;
}

.btn-outline-snapplify {
    color: #B7D13B;
    background: transparent none;
    border-color: #B7D13B;
}

.btn-outline-snapplify:hover {
    color: #fff;
    background-color: #B7D13B;
    border-color: #B7D13B
}

.btn-outline-snapplify.focus, .btn-outline-snapplify:focus {
    box-shadow: 0 0 0 2px rgba(175, 209, 0, 0.5)
}

.btn-outline-snapplify.disabled, .btn-outline-snapplify:disabled {
    color: #B7D13B;
    background-color: transparent
}

.btn-outline-snapplify.active, .btn-outline-snapplify:active, .show > .btn-outline-snapplify.dropdown-toggle {
    color: #fff;
    background-color: #B7D13B;
    border-color: #B7D13B
}

.btn-snapplify {
    color: #FFFFFF !important;
    background-color: #b7c73b;
}

.btn-snapplify:hover,
.btn-snapplify:focus,
.btn-snapplify:active,
.btn-snapplify.active,
.open .dropdown-toggle.btn-snapplify {
    color: #FFFFFF;
    background-color: #6C963F;
}

.btn-snapplify:active,
.btn-snapplify.active,
.open .dropdown-toggle.btn-snapplify {
    background-image: none;
}

.btn-snapplify.disabled,
.btn-snapplify[disabled],
fieldset[disabled] .btn-snapplify,
.btn-snapplify.disabled:hover,
.btn-snapplify[disabled]:hover,
fieldset[disabled] .btn-snapplify:hover,
.btn-snapplify.disabled:focus,
.btn-snapplify[disabled]:focus,
fieldset[disabled] .btn-snapplify:focus,
.btn-snapplify.disabled:active,
.btn-snapplify[disabled]:active,
fieldset[disabled] .btn-snapplify:active,
.btn-snapplify.disabled.active,
.btn-snapplify[disabled].active,
fieldset[disabled] .btn-snapplify.active {
    background-color: #6c943e;
}

.card {
    background-color: #f5f5f5;
    margin: 0 0 16px 0;
}

.card-footer .btn-save,
.card-footer .btn-search {
    margin: 0 10px 0 0;
}

.card-body {
    padding: 1.25rem;
}

.card-body p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.card > .card-header {
    margin: 0;
}

.card > .card-header:not(:first-child) {
    background: none;
}

.card .card-header .fa {
    color: rgba(0, 0, 0, 0.5);
}

.card .card-header .fab {
    color: rgba(0, 0, 0, 0.5);
}

.card-header i {
    color: rgba(0, 0, 0, 0.34);
}

.entity-view {
    margin-bottom: 1rem;
    width: 100%;
    padding: 0;
}

.entity-view dd {
    margin-bottom: 0;
}

.entity-view > h5 {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.entity-view .entity-section {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
}

.entity-view .statcard {
    padding: 0.5rem 0.5rem;
}

.statcard h5 em {
    font-style: normal;
    opacity: 0.25;
}

.integration-logo {
    padding-right: 0 !important;
}

.integration-logo div {
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-left: -10px;
}

.integration-google {
    background: url("../img/google.png") no-repeat center center;
    background-size: contain;
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
}

.integration-microsoft {
    background: url("../img/microsoft.png") no-repeat center center;
    background-size: contain;
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
}

.integration-blackboard {
    background: url("../img/blackboard.png") no-repeat center center;
    background-size: contain;
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
}

.integration-snapplify {
    background: url("../img/snappstar.png") no-repeat center center;
    background-size: contain;
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
}

.google-logo {
    background: rgba(0, 0, 0, 0.05) url("../img/google.png") center center no-repeat;
    background-size: 100%;
}

.user-avatar-wrap {
    display: flex;
}

.user-avatar {
    display: inline-block;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    background-size: 100%;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    margin: 0 10px 0 0;
    overflow: hidden;
}

.user-avatar-img-wrap {
    margin-right: 1rem;
}

.user-avatar-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.user-avatar .user-avatar-img {
    background: rgba(0, 0, 0, 0.07) url("../img/section-users.png") center center no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
}

.user-avatar.user-avatar-md {
    width: 50px;
    height: 50px;
    margin: 0;
}

.user-avatar-slim {
    margin: -0.25em 0 -0.5em 0;
}

.user-avatar-slim .user-avatar {
    width: 24px;
    height: 24px;
}

.user-avatar-slim .user-avatar-img-wrap {
    margin-right: 0.5em;
    align-items: center;
    display: flex;
}

table.nowrap td, table.nowrap th {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

table.table-borderless td, table.table-borderless th {
    border-top: none;
    border-color: rgba(0, 0, 0, 0.02);
}

table.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.03);
}

table th.dateColumn {
    width: 200px;
}

table.table-contained {
    border: 1px solid #eaeaea;
    border-radius: 4px;
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom: 1px solid #e2e2e2;
}

.table-bordered td, .table-bordered th {
    border: 1px solid #eceeef;
}

.table-paginated thead {
    background-color: #f5f5f5;
}

.table thead {
    background-color: #f5f5f5;
}

label {
    font-size: 20px;
}

.hint {
    display: block;
    font-size: 14px;
    opacity: 0.5;
}

input.error {
    border-color: red;
}

label.error, input.error {
    border-color: red;
    font-size: 14px;
    margin: 5px 0 0 0;
    color: red;
}

.table-pagination {
  margin: 0;
}

.table-pagination .pagination {
  align-items: center;
  margin: 0;
}

.table-pagination button {
  float: right;
  margin-right: 10px;
}

.pagination {
  color: #696969;
  align-items: center;
}

.pagination-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0.75em;
}

@media (max-width: 600px) {
  #pagination {
    margin: 0 0 10px 0;
  }

  .pagination-wrap.top {
    justify-content: center;
  }

  .table-pagination {
    flex-direction: column;
  }

  .pagination-info {
    margin: 0 0 10px 0;
  }

  #pagination .page-link {
    padding: .5rem !important;
  }
}

.pagination-wrap.top {
  /*margin-top: -20px;
  margin-bottom: 20px;*/
  /*border: 1px solid #eceeef;*/
  /*border-width: 1px 1px 0 1px;*/
  /*background-color: #f5f5f5;*/
  padding: 0.5em;
}

.pagination-wrap.bottom {
  margin-top: -20px;
  margin-bottom: 20px;
  /*border: 1px solid #eceeef;*/
  /*border-width: 0 1px 1px 1px;*/
  /*background-color: #f5f5f5;*/
  padding: 0.5em;
}

.pagination-info {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.pagination-info em {
  font-style: normal;
  font-weight: 400;
}

.badge-snapplify {
  background-color: #B7D13B;
  color: #fff;
}

.badge-snapplify-large {
  padding: 5px 10px;
  font-size: 20px;
}

.badge_icon {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.badge_icon .fa {
    font-size: 40px;
    margin: 0 10px 0 0;
    color: rgba(0,0,0,0.5);
}

.display-price {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
    margin: 0 0 5px 0;
}

.badge-default {
    background-color: #aaa;
    color: #fff;
}

.btn-full {
    width: 100%;
}

.advanced-filter #q {
    height: 61px;
    border: 4px solid #B6CF3A;
    padding: 24px 22px;
    font-size: 17px;
    float: left;
}

.advanced-filter .btn-search {
    width: 61px;
    height: 61px;
    padding: 20px;
    margin: 0 10px 0 0;
    float: left;
    background: #B6CE3A url('../img/standards/search_icon.svg') center center no-repeat;
    text-indent: -9999px;
    border: none;
}

.advanced-filter .btn-search-text {
    margin: 0 0 0 10px;
    padding-left: 40px;
    background: #B6CE3A url('../img/standards/search_icon.svg') 10px center no-repeat;
    border: 1px solid #B6CE3A;
}

/* ------------- */
/* ------------- */
/* ------------- */

/* ------------ */
/* -- Footer -- */
/* ------------ */

footer {
    padding: 0;
    background: #F8F8F8;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
}

footer .footer_spacing {
    padding-top: 30px;
    padding-bottom: 30px;
}

footer h4 {
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 15px;
}

footer ul {
    margin: 0;
    padding: 0;
}

footer ul li {
    text-align: left;
}

footer p, footer ul li a {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
}

footer .btn {
    height: 50px;
    font-size: 16px;
}

footer p.app_text {
    color: #4A4A4A;
    font-size: 13px;
    font-weight: normal;
}

footer ul li a {
    color: #B9D13B;
}

footer ul li a:hover {
    color: #B9D13B;
    text-decoration: none;
}

footer .form-group {
    margin: 0;
}

footer input {
    font-size: 13px;
    color: #9B9B9B;
    padding: 5px 15px;
    width: 100%;
    margin: 0 0 10px 0;
}

footer .btn {
    width: 100%;
}

footer .app_links a {
    float: left;
    margin: 0 6px 0 0;
}

footer .app_links img {
    width: 169px;
    height: auto;
    margin: 24px 0 0 0;
}

footer .foundation_logo {
    display: flex;
    justify-content: center;
    align-items: start;
    margin: 0 0 40px 0;

    img {
        width: 100%;
        height: auto;

        &.getSnapplify {
            margin: 0 0 24px 0;
        }
    }

    p {
        color: #6D757D;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        font-weight: normal;
        margin: 6px 0 0 0;
    }
}

@media (min-width: 768px) {
    footer .newsletter {
        margin-left: 20px;
    }

    footer .foundation_logo {
        border-left: 1px solid #D0D0D0;
        border-right: 1px solid #D0D0D0;
    }
}

.copyright_bar {
    padding: 12px 0;
}

.copyright_bar .footer_logo {
    width: 117px;
    height: 29px;
    margin: 0 auto;
    display: block;
}

.copyright_bar p {
    font-size: 12px;
    margin: 20px 10px 0 0;
    text-align: center;
}

.copyright_bar ul {
    text-align: center;
    margin: 10px 0 0 0;
    padding: 0;
}

.copyright_bar ul li {
    color: #6C953C;
    font-size: 12px;
    display: inline-block;
}

@media (min-width: 768px) {
    .copyright_bar .col-info {
        display: flex;
        align-items: center;
    }

    .copyright_bar .footer_logo {
        width: 117px;
        height: 29px;
        float: left;
        margin: 0 16px 0 0;
        text-align: left;
    }

    .copyright_bar p {
        font-size: 12px;
        color: #9B9B9B;
        margin: 5px 10px 0 0;
        float: left;
    }

    .copyright_bar ul {
        float: left;
        padding: 0;
    }

    .copyright_bar ul li {
        color: #6C953C;
        font-size: 12px;
        float: left;
        margin: 0 5px 0 0;
    }
}

.copyright_bar ul li a {
    color: #9B9B9B;
    font-size: 12px;
    text-decoration: none;
}

.copyright_bar .social_icons {
    text-align: center;
    margin: 20px 0 0 0;
}

.copyright_bar .social_icon {
    width: 31px;
    height: 31px;
    display: inline-block;
}

.copyright_bar .social_icon img {
    width: 31px;
    height: 31px;
}

@media (min-width: 768px) {
    .copyright_bar .social_icons {
        text-align: left;
        margin: 0;
    }

    .copyright_bar .social_icon {
        float: right;
        margin: 0 9px 0 0;
    }

    .copyright_bar .social_icon:first-child {
        margin: 0 0 0 0;
    }
}

/* ------------ */
/* ------------ */
/* ------------ */

/* --------- */
/* -- OLD -- */
/* --------- */

.landing-container {
    flex: 1 0 auto;
    display: flex;
    width: 100%;
    padding-top: 51px;
    font-weight: 400;
}

.landing-container .app-navbar {
    margin-bottom: 0;
    border-bottom: 1px solid #d4d4d4;
    background: #fafafa;
    padding: 0 15px !important;
}

.landing-container .app-navbar .navbar-brand {
    margin-left: 5px;
    line-height: 42px;
}

.landing-container .app-navbar .navbar-nav {
    flex-direction: row !important;
}

.landing-container .layout-inner {
    flex: 1;
    width: 100%;
}

.landing-container .body-container {
    flex: 1;
    overflow-x: auto;
    background: white;
    margin-right: 1rem;
    margin-top: 1rem;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
    padding: 1rem 2rem 40px 2rem;
}

.landing-container h2 {
    color: #24262A;
    font-size: 70px;
    font-weight: 700;
    line-height: 76px;
    padding: 0 0 24px;
}

.landing-container .caption {
    padding-top: 360px;
    position: relative;
}

.landing-container .caption p {
    padding-bottom: 27px;
    color: #4A4A4A;
    font-size: 20px;
    line-height: 30px;
}

.landing-container p {
    color: #4A4A4A;
    font-size: 20px;
    line-height: 30px;
}

.landing-container .landscape-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 370px;
    opacity: 1;
}

.landing-container .landscape-img img {
    width: 100%;
    height: auto;
}

.landing-container .caption .caption-content {
    width: 60%;
    position: relative;
}

.landing-container .caption .img-block {
    width: 47% !important;
    position: absolute;
    right: -86px !important;
    bottom: -42px;
}

.btn-primary {
    color: #FFFFFF !important;
    background-color: #b7c73b !important;
    border-color: #b7c73b !important;
}

.btn-primary:hover {
    text-decoration: none !important;
}

.btn-primary:hover, .btn-outline-primary:hover {
    color: #FFFFFF;
}

.landing-container .landing-strip {
    height: 415px;
}

.landing-container .landing-strip .caption {
    padding-top: 20px;
    position: relative;
}

.landing-container h4 {
    color: #24262A;
    font-size: 26px;
    line-height: 34px;
    padding: 0 0 30px;
    font-weight: 400;
}

.splash-layout .right-nav {
    flex-direction: row;
}

.splash-layout footer {
    padding: 0;
    background: #F8F8F8;
    border-top: 1px solid rgba(0,0,0,0.1);
}

.splash-layout .lower-footer {
    padding-top: 15px;
    padding-bottom: 20px;
}

.splash-layout .footer-logo {
    display: inline-block;
}

.splash-layout .footer-logo img {
    width: 100%;
}

.splash-layout .footer-right {
    padding: 4px 0;
}

.splash-layout .footer-right p, .splash-layout .footer-right ul {
    display: inline-block;
    vertical-align: middle;
    list-style: none;
}

.splash-layout footer .social-icons {
    margin-left: 20px;
}

.splash-layout .footer-right ul li {
    float: left;
    margin-right: 15px;
}

.splash-layout .footer-right ul li a {
    color: #6C953C;
}

@media (max-width: 1366px) {

    .splash-layout .caption .img-block {
        width: 52%;
        right: -80px;
    }

    .splash-layout .odd-even-block .odd-block .img-block {
        margin-left: -41px;
        width: 650px;
    }

    .splash-layout .odd-even-block .odd-block .content-block {
        width: 411px;
        padding: 66px 0 20px 85px;
    }

    .splash-layout .odd-even-block .even-block .content-block {
        width: 411px;
        padding: 66px 85px 20px 0;
    }

    .splash-layout .odd-even-block .even-block .img-block {
        width: 650px;
        margin-right: -47px;
    }
}

@media (max-width: 1199px) {
    .splash-layout h2 {
        font-size: 60px;
        line-height: 64px;
        padding: 0 0 15px;
    }

    .splash-layout h4 {
        font-size: 22px;
        line-height: 30px;
        padding: 0 0 15px;
    }

    .splash-layout .caption p {
        font-size: 18px;
        line-height: 28px;
        padding-bottom: 20px;
    }

    .splash-layout .caption {
        padding-top: 221px;
    }

    .splash-layout .caption .img-block {
        width: 48%;
        right: -31px;
    }

    .splash-layout .main-content .odd-even-block .img-block {
        margin-left: 0;
        width: 59%;
        margin-right: 0;
    }

    .splash-layout .main-content .odd-even-block .content-block {
        width: 40%;
        padding: 36px 0 20px 35px;
    }

    .splash-layout .main-content .odd-even-block .even-block .content-block {
        padding: 36px 35px 20px 0;
    }

    .splash-layout .odd-even-block .block {
        padding: 41px 30px 0;
    }

    .splash-layout .primary-btn {
        font-size: 14px;
        padding: 15px 22px;
        min-width: 170px;
    }

    .splash-layout .secondary-btn {
        font-size: 14px;
        padding: 15px 22px;
        min-width: 170px;
    }

    .splash-layout form input.form-control {
        padding: 12px 19px 12px;
    }

    .splash-layout .landscape-img {
        height: 290px;
        top: 54px;
    }

    .splash-layout .divider-img {
        height: 400px;
    }

    .splash-layout .snappvideo-btn {
        height: 190px;
    }
}

@media (max-width: 1023px) {
    .splash-layout h2 {
        font-size: 58px;
        line-height: 64px;
    }

    .splash-layout .caption {
        padding-top: 180px;
    }

    .splash-layout .caption .caption-content {
        width: 56%;
    }

    .splash-layout .caption .img-block {
        width: 46%;
        right: -9px;
    }

    .splash-layout .odd-even-block .block {
        padding: 40px 0px 0;
    }

    .splash-layout .landscape-img {
        height: 240px;
        top: 64px;
    }

    .splash-layout .divider-img {
        height: 240px;
    }

    .splash-layout .snappvideo-btn {
        height: 140px;
    }

}

@media (max-width: 991px) {
    .splash-layout h2 {
        font-size: 42px;
        line-height: 46px;
    }

    .splash-layout h4 {
        font-size: 20px;
        line-height: 28px;
    }

    .splash-layout .header-left {
        width: 27%;
    }

    .splash-layout .header-right {
        width: 73%;
    }

    .splash-layout header {
        padding: 15px 0;
    }

    .splash-layout header .nav-menu li a {
        line-height: 17px;
        font-size: 13px;
    }

    .splash-layout .dropdown .btn {
        font-size: 13px;
        line-height: 17px;
        padding: 4px 8px;
    }

    .splash-layout .dropdown .btn i {
        top: 3px;
    }

    .splash-layout header ul li {
        margin-left: 20px;
    }

    .splash-layout .login-panel {
        margin-left: 10px;
    }

    .splash-layout .login-panel span {
        font-size: 13px;
        line-height: 17px;
    }

    .splash-layout .login-panel li a {
        font-size: 13px;
        line-height: 17px;
    }

    .splash-layout .dropdown-menu > li > a {
        padding: 7px 14px;
    }

    .splash-layout .caption {
        padding-top: 180px;
    }

    .splash-layout .logo {
        width: 150px;
    }

    .splash-layout .caption p {
        font-size: 16px;
        padding-bottom: 10px;
        line-height: 26px;
        padding-right: 30px;
    }

    .splash-layout .primary-btn {
        padding: 8px 15px;
        min-width: 140px;
        font-size: 13px;
        min-width: 120px;
    }

    .splash-layout .secondary-btn {
        padding: 8px 15px;
        min-width: 140px;
        font-size: 13px;
        min-width: 120px;
    }

    .splash-layout form input.form-control {
        height: 38px;
        line-height: 11px;
    }

    .splash-layout .left-footer.col-sm-2 {
        width: 22%;
    }

    .splash-layout footer .col-sm-10 {
        width: 78%;
    }

    .splash-layout footer .social-icons {
        width: 100%;
        margin: 15px 0 0;
        text-align: right;
    }

    .splash-layout footer .social-icons li {
        float: none;
        display: inline-block;
    }

    .splash-layout .lower-footer {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .splash-layout .landing-banner {
        height: 695px;
    }

    .splash-layout .landscape-img {
        height: 210px;
        top: 74px;
    }

    .splash-layout .divider-img {
        height: 410px;
    }

    .splash-layout .snappvideo-btn {
        height: 160px;
    }
}

@media (max-width: 767px) {

    .splash-layout .landing-banner {
        height: 800px;
    }

    .splash-layout .image-strip{
        height: 580px;
    }

    .splash-layout .landing-strip{
        height: 520px;
    }

    .splash-layout .landing-strip.third{
        height: 460px;
    }

    .splash-layout .image-strip.first .caption {
        padding-top: 90px;
    }

    .splash-layout .image-strip.second .caption {
        padding-top: 200px;
    }

    .splash-layout .image-strip.forth .caption {
        padding-top: 6px;
    }

    .splash-layout h2 {
        font-size: 30px;
        line-height: 36px;
        padding: 0 0 10px;
    }

    .splash-layout h4 {
        font-size: 18px;
        line-height: 23px;
        padding: 0 0 27px;
    }

    .splash-layout h6 {
        padding: 0 0 15px;
    }

    .splash-layout .logo {
        width: 95px;
    }

    .splash-layout header {
        padding: 11px 0;
    }

    .splash-layout header .container {
        padding: 0 9px;
    }

    .splash-layout .caption .img-block {
        display: none;
    }

    .splash-layout .caption .caption-content {
        width: 100%;
        max-width: 530px;
        margin: 0 auto;
    }

    .splash-layout .caption .caption-content {
        text-align: center;
    }

    .splash-layout .caption {
        padding-top: 52px;
    }

    .splash-layout .caption p {
        padding: 0 0 27px;
        font-size: 16px;
        line-height: 22px;
    }

    .splash-layout .primary-btn {
        min-width: 200px;
        font-size: 14px;
        line-height: 16px;
        padding: 16px 15px;
    }

    .splash-layout .secondary-btn {
        min-width: 200px;
        font-size: 14px;
        line-height: 16px;
        padding: 16px 15px;
    }

    .splash-layout .main-content .odd-even-block .img-block {
        width: 100%;
        padding: 0 5px;
    }

    .splash-layout .main-content .odd-even-block .content-block {
        width: 100%;
        text-align: center;
        padding: 36px 0 30px;
        max-width: 475px;
        display: block;
        margin: 0 auto;
    }

    .splash-layout .odd-even-block .block {
        padding: 0px;
    }

    .splash-layout .main-content .odd-even-block .even-block .content-block {
        padding: 36px 0 30px;
    }

    .splash-layout .subscribe-block {
        padding: 34px 5px 10px;
    }

    .splash-layout footer .col-sm-4 {
        margin-bottom: 23px;
    }

    .splash-layout footer .subscribe-block p {
        padding: 0px 0 5px;
    }

    .splash-layout form input.form-control {
        margin-bottom: 12px;
        line-height: 18px;
        padding: 10px 19px 8px;
    }

    .splash-layout footer form .btn {
        margin-top: 9px;
        width: auto;
    }

    .splash-layout footer form {
        text-align: center;
    }

    .splash-layout .lower-footer {
        text-align: center;
        padding-top: 26px;
    }

    .splash-layout .left-footer.col-sm-2 {
        width: auto;
    }

    .splash-layout footer .right-footer {
        width: 100%;
        text-align: center;
    }

    .splash-layout .footer-right ul li {
        display: inline-block;
        float: none;
    }

    .splash-layout .footer-right p, .footer-right ul {
        display: block;
    }

    .splash-layout .footer-logo {
        margin: 0 0 16px;
    }

    .splash-layout .copyright {
        line-height: 13px;
    }

    .splash-layout .social-icons {
        text-align: center !important;
    }

    .splash-layout footer .social-icons {
        margin: 17px 0 0;
    }

    .splash-layout .social-icons li {
        margin-right: 10px;
    }

    .splash-layout .hamburger-icon {
        width: 11px;
        padding: 4px 0;
        z-index: 2;
        float: left;
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .splash-layout .hamburger-icon span {
        width: 100%;
        height: 2px;
        background: #9B9B9B;
        display: block;
        border-radius: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        -ms-border-radius: 0px;
    }

    .splash-layout .hamburger-icon:before {
        content: "";
        height: 2px;
        background: #9B9B9B;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        border-radius: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        -ms-border-radius: 0px;
    }

    .splash-layout .hamburger-icon:after {
        content: "";
        height: 2px;
        background: #9B9B9B;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        -ms-border-radius: 0px;
    }

    .splash-layout .profile-icon {
        position: absolute;
        right: 50px;
        top: 16px;
    }

    .splash-layout .nav-menu {
        position: fixed;
        right: -100%;
        top: 45px;
        bottom: 0;
        background: #F4F4F4;
        width: 230px;
    }

    .splash-layout .profile-icon i {
        font-size: 14px;
        line-height: 14px;
        color: #9B9B9B;
    }

    .splash-layout .open-menu {
        overflow: hidden;
    }

    .splash-layout .open-menu .hamburger-icon span {
        opacity: 0;
    }

    .splash-layout .open-menu .hamburger-icon:before {
        transform: rotate(45deg) translateX(-3px) translateY(9px);
        -moz-transform: rotate(45deg) translateX(-3px) translateY(9px);
        -webkit-transform: rotate(45deg) translateX(-3px) translateY(9px);
        -ms-transform: rotate(45deg) translateX(-3px) translateY(9px);
        right: -1px;
    }

    .splash-layout .open-menu .hamburger-icon:after {
        transform: rotate(-45deg) translateX(-3px) translateY(-8px);
        -moz-transform: rotate(-45deg) translateX(-3px) translateY(-8px);
        -webkit-transform: rotate(-45deg) translateX(-3px) translateY(-8px);
        -ms-transform: rotate(-45deg) translateX(-3px) translateY(-8px);
        right: 0.5px;
    }

    .splash-layout .nav-menu {
        padding: 50px 20px;
    }

    .splash-layout .open-menu .nav-menu {
        right: 0;
    }

    .splash-layout header ul {
        text-align: left;
    }

    .splash-layout header ul li {
        display: block;
        margin: 0;
        float: none;
        margin-bottom: 15px;
    }

    .splash-layout .dropdown .btn {
        padding: 0;
    }

    .splash-layout .dropdown .btn i {
        top: 3px;
        left: 120px;
        right: auto;
    }

    .splash-layout .dropdown-menu {
        padding: 10px 20px 0px 20px;
    }

    .splash-layout .dropdown-menu {
        position: static;
        float: none;
        box-shadow: none;
        background: transparent;
        border: 0;
    }

    .splash-layout .dropdown-menu li {
        margin-bottom: 10px;
    }

    .splash-layout .dropdown-menu li:last-child {
        margin-bottom: 0;
    }

    .splash-layout .dropdown-menu li a {
        padding: 0;
    }

    .splash-layout .dropdown-menu li a:hover {
        background: transparent;
        color: #B9D13B;
    }

    .splash-layout .small-header header {
        padding: 5px 0;
    }

    .splash-layout .login-panel {
        display: none;
        position: absolute;
        right: 10px;
        top: 50px;
        margin: 0;
        background: #F4F4F4;
        padding: 10px;
        text-align: center;
        box-shadow: 0 0 10px 0 #ccc;
    }

    .splash-layout .login-panel:before {
        content: "";
        position: absolute;
        right: 34px;
        top: -9px;
        border-bottom: 10px solid #f4f4f4;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }

    .splash-layout .login-panel li {
        margin-bottom: 5px;
        margin-left: 0;
    }

    .splash-layout .login-panel li:before {
        width: 0;
    }

    .splash-layout .profile-open-menu .login-panel {
        display: inline-block;
    }

    .splash-layout footer .col-sm-4 {
        max-width: 415px;
        margin: 0 auto 30px;
        text-align: center;
    }

    .splash-layout header .dropdown-menu a {
        display: inline-block;
    }

    .splash-layout .open-menu header {
        background: #F4F4F4;
        box-shadow: 0 0 10px 0 #ccc;
    }

    .splash-layout .landscape-img {
        height: 120px;
        top: 64px;
    }

    .splash-layout .divider-img {
        height: 550px;
    }

    .splash-layout .snappvideo-btn {
        height: 120px;
    }

    .splash-layout .caption {
        padding-top: 190px;
    }
}

@media (max-width: 500px) {

    .splash-layout .app-navbar .navbar-brand {
        display: none !important;
    }
}

@media (max-width: 480px) {

    .splash-layout footer .col-sm-4 {
        max-width: inherit;
        margin: 0 auto 30px;
        text-align: left;
    }
}

@media (max-width: 375px) {

    .splash-layout .landscape-img {
        height: 80px;
        top: 37px;
    }

    .splash-layout .divider-img {
        height: 580px;
    }

    .splash-layout .snappvideo-btn {
        height: 180px;
    }

    .splash-layout .caption {
        padding-top: 120px;
    }

    .splash-layout .landing-banner {
        height:820px;
    }

    .splash-layout .image-strip{
        height: 600px;
    }

    .splash-layout .landing-strip{
        height: 560px;
    }

    .splash-layout .landing-strip.third{
        height: 460px;
    }

    .splash-layout .image-strip.first .caption {
        padding-top: 10px;
    }

    .splash-layout .image-strip.second .caption {
        padding-top: 200px;
    }

    .splash-layout .image-strip.forth .caption {
        padding-top: 6px;
    }

    .splash-layout .image-strip.forth .divider-img {
        height: 640px;
    }
}

/* --------- */
/* --------- */
/* --------- */

/* ----------------- */
/* -- Error Pages -- */
/* ----------------- */

    .error_content .section_content {
        display: flex;
        align-items: center;
    }

    .error_content img {
        width: 100px;
        height: auto;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 100px;
    }

/* ----------------- */
/* ----------------- */
/* ----------------- */

/* ----------------- */
/* -- Splash Page -- */
/* ----------------- */

body.landing .landing-container .body-container {
    padding: 0;
    overflow: hidden;
}

body.landing .body-container .col-12 {
    padding: 0;
}

body.landing .landing-banner {
    height: 800px;
}

body.landing .image-strip {
    position: relative;
    height: 435px;
}

body.landing .image-strip .caption {
    padding-top: 110px;
    position: relative;
}

body.landing .image-strip .caption h2 {
    color: #FFFFFF;
}

body.landing .landing-strip {
    height: 415px;
}

body.landing .landing-strip .caption {
    padding-top: 20px;
    position: relative;
}

body.landing .divider-img {
    width: 100%;
    height: 400px;
}

body.landing .divider-img img {
    width: 100% !important;
    height: auto !important;
}

body.landing footer {
    flex-shrink: 0;
    padding: 0.5rem 0;
    line-height: 30px;
    color: #9b9b9b;
    font-size: 13px;
    font-weight: normal;
    text-align: center;
}

body.landing .copyright {
    padding-bottom: 0;
    color: #9B9B9B;
    font-size: 13px;
    line-height: 16px;
}

body.landing .footer-right p, .footer-right ul {
    display: inline-block;
    vertical-align: middle;
    list-style: none;
}

body.landing .footer-right ul li {
    margin-bottom: 0;
    margin-right: 0;
    position: relative;
}

body.landing .footer-right ul li {
    float: left;
    margin-right: 15px;
}

body.landing .footer-right ul li:last-child {
    margin-right: 0px;
}

body.landing .footer-right ul li a {
    color: #6C953C;
}

body.landing .footer-right ul li a:hover {
    color: #24262a;
}

body.landing .social-icons li a {
    display: inline-block;
    height: 30px;
}

body.landing .social-icons li a span {
    color: #58585B;
    font-size: 13px;
    line-height: 15px;
    height: 30px;
    padding: 8px 17px 8px 8px;
}

body.landing .lower-footer {
    padding-top: 15px;
    padding-bottom: 20px;
}

body.landing .footer-logo {
    display: inline-block;
}

body.landing .footer-logo img {
    width: 100%;
}

body.landing .footer-right {
    padding: 4px 0;
}

body.landing .col-sm-3.left-footer {
    width: 17.666667%;
}

footer .social-icons {
    margin-left: 20px;
}

body.landing .terms-panel li:before {
    content: "";
    position: absolute;
    left: -8px;
    top: 4px;
    bottom: 4px;
    width: 1px;
    background: #9B9B9B;
}

body.landing .terms-panel li:first-child:before {
    width: 0;
}

body.landing .social-icons li a:hover svg polygon {
    fill: #000;
}

body.landing .social-icons li a:hover span {
    background: #6C953C;
    color: #fff;
}

.asset {
    width: 100%;
    float: left;
    margin: 0 0 10px 0;
}

@media (min-width: 768px) and (max-width: 991px) {
    .asset {
        width: calc((100% / 4) - 26px);
        margin: 0 13px 10px 13px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .asset {
        width: calc((100% / 5) - 28px);
        margin: 0 14px 20px 14px;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .asset {
        width: calc((100% / 7) - 30px);
        margin: 0 15px 20px 15px;
    }
}

@media (min-width: 1400px) {
    .asset {
        width: calc((100% / 7) - 30px);
        margin: 0 15px 20px 15px;
    }
}

div.show-image {
    cursor: pointer;
    display: block;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 216px;
    margin: 0 0 10px 0;
}

div.show-image.jacket-image {
    width: 260px;
    height: 400px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    background: rgba(0, 0, 0, 0.05) url("../img/noimg.svg") center center no-repeat;
    background-size: 100%;
}

div.show-image img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
    object-fit: contain;
}

.image-badges {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
}

.returnDates {
    margin: 16px 0 0 0;
}

.returnDates span {
    width: 100%;
}

.nav-tabs {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 0;
}

.nav-tabs .nav-item, .nav-pills .nav-item {
    margin-bottom: -1px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 1.17px;
    line-height: 18px;
}

/* ----------------- */
/* ----------------- */
/* ----------------- */

/* ---------------- */
/* -- Empty List -- */
/* ---------------- */

.empty-list img {
    width: 120px;
    height: 120px;
    margin: 0 auto 10px auto;
    display: block;
}

.empty-list img.smallImage {
    width: 100px;
    height: auto;
}

.empty-list .emptyListContent {
    text-align: center;
}

@media (min-width: 768px) {
    .empty-list {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        color: #ADADAD;
    }

    .empty-list img {
        width: 120px;
        height: auto;
        margin: 0 10px 0 0;
    }

    .empty-list img.smallImage {
        width: 100px;
        height: auto;
        margin: 0 20px 0 0;
    }

    .empty-list .emptyListContent {
        margin: auto 0;
        text-align: left;
    }
}

/* ---------------- */
/* ---------------- */
/* ---------------- */
