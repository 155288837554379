/*
 * DO NOT EDIT THIS FILE DIRECTLY
 * Compiled from bootstrap-tagmanager.less based on Bootstrap 2.3.1 variables
 * https://github.com/twitter/bootstrap/blob/master/less/variables.less
 */
/*.tm-tag {*/
/*color: #ffffff;*/
/*background-color: #5BB75B;*/
/*border: #bbbbbb 1px solid;*/
/*box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;*/
/*display: inline-block;*/
/*border-radius: 3px;*/
/*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
/*font-size: 13px;*/
/*margin: 0 5px 5px 0;*/
/*padding: 4px;*/
/*text-decoration: none;*/
/*transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;*/
/*-moz-transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;*/
/*-webkit-transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;*/
/*vertical-align: middle;*/
/*}*/


.tm-tag .tm-tag-remove {
    color: #ffffff;
    font-weight: bold;
    margin-left: 4px;
    opacity: 0.2;
}

.tm-tag .tm-tag-remove:hover {
    color: #000000;
    text-decoration: none;
    opacity: 0.4;
}

.tm-tag.tm-tag-warning {
    color: #945203;
    background-color: #f2c889;
    border-color: #f0a12f;
}

.tm-tag.tm-tag-error {
    color: #84212e;
    background-color: #e69ca6;
    border-color: #d24a5d;
}

.tm-tag.tm-tag-success {
    color: #638421;
    background-color: #cde69c;
    border-color: #a5d24a;
}

.tm-tag.tm-tag-info {
    color: #4594b5;
    background-color: #c5eefa;
    border-color: #5dc8f7;
}

.tm-tag.tm-tag-inverse {
    color: #cccccc;
    background-color: #555555;
    border-color: #333333;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2) inset;
}

.tm-tag.tm-tag-inverse .tm-tag-remove {
    color: #ffffff;
}

.tm-tag.tm-tag-large {
    font-size: 16.25px;
    border-radius: 4px;
    padding: 11px 7px;
}

.tm-tag.tm-tag-small {
    font-size: 11.049999999999999px;
    border-radius: 3px;
    padding: 2px 4px;
}

.tm-tag.tm-tag-mini {
    font-size: 9.75px;
    border-radius: 2px;
    padding: 0px 2px;
}

.tm-tag.tm-tag-plain {
    color: #333333;
    box-shadow: none;
    background: none;
    border: none;
}

.tm-tag.tm-tag-disabled {
    color: #aaaaaa;
    background-color: #e6e6e6;
    border-color: #cccccc;
    box-shadow: none;
}

.tm-tag.tm-tag-disabled .tm-tag-remove {
    display: none;
}

input[type="text"].tm-input {
    margin-bottom: 5px;
    vertical-align: middle !important;
    max-width: 100px;
}

.control-group.tm-group {
    margin-bottom: 5px;
}

.form-horizontal .control-group.tm-group {
    margin-bottom: 15px;
}
