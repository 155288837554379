/* ------------ */
/* -- Common -- */
/* ------------ */

body {
    background: #fff;
    color: #4A4A4A;
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1270px;
    }
}

.container .container_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.right {
        align-items: flex-end;
    }
}

h2 {
    font-size: 20px;
    line-height: 20px;
}

h3 {
    font-weight: bold;
    font-size: 17px;
}

h4 {
    font-size: 14px;
    color: #24262A;
    font-weight: bold;
}

p {
    font-size: 11px;
    line-height: 17px;
}

p.big {
    font-size: 14px !important;
}

p:last-child {
    margin-bottom: 0;
}

a:hover {
    color: #cee279;
    text-decoration: none;
}

.btn-outline-secondary {
    border: none;
    outline: none;
}

.btn-outline-secondary:hover {
    background: none;
    border: none;
    color: #58595b;
    outline: none;
    text-decoration: underline;
}

.block_404 {
    color: white;
    text-align: left !important;
}

.block_404 h1 {
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
}

.block_404 h2 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
}

.block_404 ul {
    font-size: 12px;
    font-weight: 400;
    list-style: disc;
}

.block_404 a {
    color: #B9D13B;
}

.return_home_button {
    display: inline-block;
    margin: 0;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
}

.white_text {
    color: #fff;
}

@media (min-width: 768px) {
    h2 {
        font-size: 28px;
        line-height: 28px;
    }

    h3 {
        font-size: 21px;
    }

    p {
        font-size: 14px;
        line-height: 21px;
    }

    p.big {
        font-size: 17px !important;
    }

    .block_404 h1 {
        font-size: 43px;
        line-height: 76px;
    }

    .block_404 h2 {
        font-size: 20px;
        line-height: 25px;
    }

    .block_404 ul {
        font-size: 15px;
        font-weight: 400;
    }
}

@media (min-width: 992px) {
    h2 {
        font-size: 36px;
        line-height: 36px;
    }

    h3 {
        font-size: 25px;
    }

    p {
        font-size: 16px;
        line-height: 25px;
    }

    p.big {
        font-size: 20px !important;
    }

    .block_404 h1 {
        font-size: 56px;
        line-height: 76px;
    }

    .block_404 h2 {
        font-size: 25px;
        line-height: 30px;
    }

    .block_404 ul {
        font-size: 17px;
        font-weight: 400;
    }
}

@media (min-width: 1200px) {
    h2 {
        font-size: 45px;
        line-height: 48px;
    }

    h3 {
        font-size: 30px;
    }

    p {
        font-size: 19px;
        line-height: 30px;
    }

    p.big {
        font-size: 23px !important;
    }

    .block_404 h1 {
        font-size: 70px;
        line-height: 76px;
    }

    .block_404 h2 {
        font-size: 30px;
        line-height: 35px;
    }

    .block_404 ul {
        font-size: 20px;
        font-weight: 400;
    }
}

.btn-snapplify.btn-dark {
    background-color: #6C963F;
    border-color: #6C963F;
}

/* ------------ */
/* ------------ */
/* ------------ */

/* ------------ */
/* -- Header -- */
/* ------------ */

/* ----------- */
/* -- Login -- */
/* ----------- */

.login_bar {
    padding: 27px 0;
}

@media (min-width: 768px) {
    .login_bar {
        padding: 5px 0 0 0;
    }
}

@media (min-width: 1200px) {
    .login_bar {
        padding: 27px 0 0 0;
    }
}

.login_bar p {
    text-align: center;
    font-size: 14px;
}

.login_bar .selectedCountry,
.logged-in .selectedCountry {
    width: 25px;
    height: 24px;
    border-radius: 50%;
    margin: 0 0 0 5px;
    display: inline-block;
}

@media (min-width: 768px) {
    .login_bar p {
        text-align: right;
        float: right;
        margin: 0 10px 0 0;
    }

    .login_bar .selectedCountry,
    .logged-in .selectedCountry {
        margin: 0 0 0 10px;
    }
}

@media (max-width: 1199px) {
  .logged-in .selectedCountry {
    margin: -25px 0 0 0;
  }
}

.login_bar a {
    font-weight: bold;
    color: #4A4A4A;
    text-decoration: none;
}

.login_bar .logged-in {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
}

/* ----------- */
/* ----------- */
/* ----------- */

/* ---------- */
/* Navigation */
/* ---------- */

nav.nav {
    display: block;
    padding: 0;
}

.nav img {
    width: 94px;
    height: 23px;
    margin: 10px 0 0 0;
}

.nav .container {
    display: block !important;
}

.nav .navbar-toggler {
    float: right;
    border: none;
}

.nav ul {
    margin: 0;
    padding: 29px 0 0 0;
    list-style: none;
}

.nav ul li {
    float: left;
    margin: 0 30px 0 0;
}

.nav ul li a {
    text-transform: uppercase;
    text-decoration: none;
    color: #4A4A4A;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    padding: 0 0 5px 0;

    &.dropdown-item {
        padding: .25rem 1.5rem;

        &:focus {
            background: none;
        }
    }

    &.active {
        border-bottom: 4px solid #9FBB34;
    }
}

.nav ul li.user {
    font-size: 16px;
    color: #24262A;
    font-weight: normal;
    text-transform: none;
}

.nav ul li.user a {
    font-size: 16px;
    color: #24262A;
    font-weight: normal;
    text-transform: none;
}

.nav ul li.user p {
    font-size: 16px;
    color: #24262A;
}

@media (min-width: 992px) {
    .nav .container {
        padding-top: 23px;
        padding-bottom: 23px;
    }

    .nav .hideRobot {
        background: none;
    }

    .nav img {
        width: 220px;
        height: 57px;
        padding: 0;
        margin: 0;
    }

    .nav ul {
        padding: 26px 0 0 0;
    }
}

@media (min-width: 1200px) {
    .nav .container {
        padding-top: 21px;
        padding-bottom: 21px;
    }

    .nav ul li:first-child {
        float: left;
        margin: 0 30px 0 68px;
    }

    .nav ul li.user {
        position: absolute;
        right: 0;
        bottom: -10px;
        margin: 0;
        display: block;
    }

    .nav ul li.user a {
        float: left;
    }

    .nav ul li.user p {
        float: left;
        margin: 0 10px 0 0;
        line-height: 40px;
    }

    .nav ul li.user span.account-balance {
        float: left;
        margin: 0 10px 0 0;
        line-height: 40px;
    }

    .nav ul li.user .user-avatar {
        margin: 0 3px;
    }

    .nav ul.logged-in {
        padding: 0;
    }
}

@media (max-width: 1199px) {
    .nav ul li {
        width: 100%;
        text-align: center;
        margin: 0 0 10px 0;
    }
}

/* ---------- */
/* ---------- */
/* ---------- */

/* ------------ */
/* ------------ */
/* ------------ */

/* ------------- */
/* -- Content -- */
/* ------------- */

section img {
    width: 100%;
    height: auto;
}

@media (max-width: 767px) {
    .content_icon {
        display: none;
    }
}
.content_icon {
    float: left;
}

@media (min-width: 768px) {
    .content_icon {
        width: 48px;
        height: 49px;
        margin: 0 22px 0 0;
    }

    .heading_content {
        float: left;
        width: calc(100% - 99px);
    }
}

@media (min-width: 992px) {
    .content_icon {
        width: 58px;
        height: 59px;
        margin: 0 26px 0 0;
    }
}

@media (min-width: 1200px) {
    .content_icon {
        width: 69px;
        height: 70px;
        margin: 0 30px 0 0;
    }
}

.section_heading, .section_content {
    margin: 0 0 34px 0;
}

.section_buttons {
    float: left;
    width: 100%;
}

/* ------------ */
/* -- Search -- */
/* ------------ */

.search {
    height: 250px;
    background: url('../img/home/search_block_bg.jpg') 0 0 no-repeat;
    background-size: cover;
    position: relative;
}

.search .container {
    height: 250px;
}

.search .flex-column {
    height: 250px;
}

.search_bar {
    width: 100%;
    display: block;
    margin: 46px 0 0 0;
}

.search_bar input {
    width: calc(100% - 27px);
    height: 27px;
    border: 2px solid #B6CF3A;
    padding: 9px 7px;
    float: left;
    font-size: 8px;
    color: #9B9B9B;
}

.search_bar .submit {
    width: 27px;
    height: 27px;
    padding: 9px;
    float: left;
    background: #B6CE3A url(../img/home/search_icon.svg) center center no-repeat;
}

@media (min-width: 768px) {
    .search {
        height: 300px;
    }

    .search .container {
        height: 300px;
    }

    .search .flex-column {
        height: 300px;
    }

    .search_bar input {
        width: calc(100% - 61px);
        height: 61px;
        border: 3px solid #B6CF3A;
        padding: 24px 22px;
        font-size: 17px;
    }

    .search_bar .submit {
        width: 61px;
        height: 61px;
        padding: 20px;
    }
}

@media (min-width: 992px) {
    .search {
        height: 400px;
    }

    .search .container {
        height: 400px;
    }

    .search .flex-column {
        height: 400px;
    }

    .search_bar input {
        width: calc(100% - 61px);
        height: 61px;
        border: 3px solid #B6CF3A;
        padding: 24px 22px;
        font-size: 17px;
    }

    .search_bar .submit {
        width: 61px;
        height: 61px;
        padding: 20px;
    }
}

@media (min-width: 1200px) {
    .search {
        height: 500px;
    }

    .search .container {
        height: 500px;
    }

    .search .flex-column {
        height: 500px;
    }

    .search_bar input {
        width: calc(100% - 61px);
        height: 61px;
        border: 4px solid #B6CF3A;
        padding: 24px 22px;
        font-size: 17px;
    }

    .search_bar .submit {
        width: 61px;
        height: 61px;
        padding: 20px;
    }
}

/* ------------ */
/* ------------ */
/* ------------ */

/* ------------- */
/* -- Top Bar -- */
/* ------------- */

.top_bar {
    height: 150px;
    background: gray;
    background-size: cover;
    position: relative;
}

.top_bar .container {
    height: 150px;
    position: relative;
}

.top_bar .flex-column {
    height: 150px;
}

.top_bar .section_content {
    margin: 0 0 14px 0;
}

@media (max-width: 991px) {
    .top_bar .closeTopBar {
        position: absolute;
        bottom: 15px;
        right: 10px;
    }
}

@media (min-width: 992px) {
    .top_bar {
        height: 200px;
    }

    .top_bar .container {
        height: 200px;
    }

    .top_bar .flex-column {
        height: 200px;
    }

    .top_bar .section_content {
        margin: 0 0 28px 0;
    }

    .top_bar .closeTopBar {
        position: absolute;
        top: 50px;
        right: 0;
    }
}

/* ------------- */
/* ------------- */
/* ------------- */

/* ------------ */
/* -- Banner -- */
/* ------------ */

.banner {
    height: 250px;
    background: gray;
    background-size: cover;
    position: relative;
}

.banner .container {
    height: 250px;
}

.banner .flex-column {
    height: 250px;
}

.banner .advanced-filter {
    margin: 25px 0 0 0;
    width: 100%;
}

@media (min-width: 768px) {
    .banner {
        height: 270px;
    }

    .banner .container {
        height: 270px;
    }

    .banner .flex-column {
        height: 270px;
    }
}

@media (min-width: 992px) {
    .banner {
        height: 300px;
    }

    .banner .container {
        height: 300px;
    }

    .banner .flex-column {
        height: 300px;
    }
}

@media (min-width: 1200px) {
    .banner {
        height: 330px;
    }

    .banner .container {
        height: 330px;
    }

    .banner .flex-column {
        height: 330px;
    }
}

/* ------------ */
/* ------------ */
/* ------------ */

/* -------------- */
/* -- Register -- */
/* -------------- */

.register {
    margin: 21px 0 58px 0;
}

/* -------------- */
/* -------------- */
/* -------------- */

/* -------------- */
/* -- Purchase -- */
/* -------------- */

.purchase {
    height: 250px;
    background: gray;
    background-size: cover;
    position: relative;
}

.purchase .container {
    height: 250px;
}

.purchase .flex-column {
    height: 250px;
}

@media (min-width: 768px) {
    .purchase {
        height: 300px;
    }

    .purchase .container {
        height: 300px;
    }

    .purchase .flex-column {
        height: 300px;
    }
}

@media (min-width: 992px) {
    .purchase {
        height: 400px;
    }

    .purchase .container {
        height: 400px;
    }

    .purchase .flex-column {
        height: 400px;
    }
}

@media (min-width: 1200px) {
    .purchase {
        height: 500px;
    }

    .purchase .container {
        height: 500px;
    }

    .purchase .flex-column {
        height: 500px;
    }
}

/* -------------- */
/* -------------- */
/* -------------- */

/* ------------ */
/* -- Blocks -- */
/* ------------ */

.blocks {
    margin: 40px 0 0 0;
}

.block {
    background: #F3F3F3;
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.12);
    padding: 35px 25px;
    text-align: center;
}

.block-col {
    margin: 0 0 40px 0;
}

.block h3 {
    margin: 0 0 33px 0;
}

.block p {
    margin-bottom: 23px;
}

.block img {
    width: auto;
    height: 59px;
    margin: 0 auto 24px auto;
    display: block;
}

.block a, .block a:hover {
    color: #fff;
}

@media (min-width: 768px) {
    .blocks {
        margin: 57px 0;
    }

    .block-col {
        margin: 0;
    }

    .block h3 {
        margin: 0 0 36px 0;
    }

    .block p {
        margin-bottom: 27px;
    }

    .block img {
        height: 65px;
        margin: 0 auto 26px auto;
    }
}

@media (min-width: 992px) {
    .blocks {
        margin: 74px 0;
    }

    .block {
        margin: 0;
    }

    .block h3 {
        margin: 0 0 39px 0;
    }

    .block p {
        margin-bottom: 31px;
    }

    .block img {
        height: 71px;
        margin: 0 auto 28px auto;
    }
}

@media (min-width: 1200px) {
    .blocks {
        margin: 91px 0 72px 0;
    }

    .block {
        margin: 0;
    }

    .block h3 {
        margin: 0 0 43px 0;
    }

    .block p {
        margin-bottom: 35px;
    }

    .block img {
        height: 77px;
        margin: 0 auto 30px auto;
    }
}

/* ------------ */
/* ------------ */
/* ------------ */

/* ----------------- */
/* -- Free Access -- */
/* ----------------- */

section.spacing {
    margin: 25px 0 0 0;
}

section.bottom_spacing {
    margin: 25px 0;
}

section.spacing h3, section.bottom_spacing h3 {
    margin: 0 0 25px 0;
}

.twitter-typeahead, .tt-hint, .tt-input, .tt-menu {
    width: 100%;
}

.scrollable-dropdown-menu .tt-menu {
    max-height: 165px;
    overflow-y: auto;
    background-color: white;
}

@media (min-width: 992px) {
    section.spacing {
        margin: 50px 0 0 0;
    }

    section.bottom_spacing {
        margin: 50px 0;
    }

    section.spacing h3, section.bottom_spacing h3 {
        margin: 0 0 50px 0;
    }
}

/* ----------------- */
/* ----------------- */
/* ----------------- */

/* ------------- */
/* ------------- */
/* ------------- */

/* ------------ */
/* -- Footer -- */
/* ------------ */

.footer_top_strip {
    width: 100%;
    background: url('../img/web/snapplify_footer.png') center no-repeat;
    background-size: cover;
    height: 250px;
    min-height: 250px;
}


.footer_top_strip > .container-fluid {
    padding: 0;
    height: 250px;
    min-height: 250px;
}

@media (min-width: 1400px) {
    .footer_top_strip {
        background-size: 100%;
    }
}

footer {
    padding: 0;
    background: #F8F8F8;
    margin: -40px 0 0 0;
}

footer .footer_spacing {
    padding-top: 40px;
}

footer h4 {
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 15px;
}

footer .footer_list div {
    margin: 0 0 22px 0;
}

footer .footer_list div:last-child {
    margin: 0;
}

@media (min-width: 768px) {
    footer .footer_list div {
        float: left;
        margin: 0 35px 0 0;
    }

    footer .footer_list div:last-child {
        margin: 0;
    }
}

@media (min-width: 1300px) {
    footer .footer_list div {
        float: left;
        margin: 0 35px 0 0;
    }

    footer .footer_list div:last-child {
        margin: 0;
    }
}

footer ul {
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
}

footer ul li {
    text-align: left;
}

footer p, footer ul li a {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
}

footer .btn {
    height: 50px;
    font-size: 16px;
}

footer ul li a {
    color: #B9D13B;
}

footer ul li a:hover {
    color: #B9D13B;
    text-decoration: none;
}

footer .form-group {
    margin: 0;
}

footer label {
    font-size: 16px;
    line-height: 16px;
}

footer input {
    font-size: 13px;
    color: #9B9B9B;
    padding: 5px 15px;
    width: 100%;
    margin: 0 0 10px 0;
}

footer .smallLabels {
    margin: 10px 0;
}

footer .smallLabels .checkBoxGroup {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
}

footer .smallLabels input {
    width: initial;
}

footer .smallLabels label {
    font-size: 14px;
    padding-left: 5px;
    border-bottom: none;
    width: calc(100% - 24px);
}

footer .btn {
    width: 100%;
}

footer .app_links {
    margin: 0 0 20px 0;
}

@media (min-width: 768px) {
    footer .app_links {
        margin: 32px 0 20px 0;
    }
}

@media (max-width: 1199px) {
    footer p.app_text {
        clear: both;
        padding: 10px 0 0 0 !important;
    }
}

footer .app_links a {
    float: left;
    margin: 0 0 25px 0;
}

.copyright_bar {
    padding: 12px 0;
}

.copyright_bar .footer_logo {
    width: 117px;
    height: 29px;
    margin: 0 auto;
    display: block;
}

.copyright_bar p {
    font-size: 12px;
    color: #9B9B9B;
    margin: 20px 10px 0 0;
    text-align: center;
}

.copyright_bar ul {
    text-align: center;
    margin: 10px 0 0 0;
    padding: 0;
}

.copyright_bar ul li {
    color: #6C953C;
    font-size: 12px;
    display: inline-block;
}

@media (min-width: 768px) {
    .copyright_bar .footer_logo {
        width: 117px;
        height: 29px;
        float: left;
        margin: 0 16px 0 0;
        text-align: left;
    }

    .copyright_bar p {
        font-size: 12px;
        color: #9B9B9B;
        margin: 5px 10px 0 0;
        float: left;
    }

    .copyright_bar ul {
        float: left;
        margin: 11px 0 0 0;
        padding: 0;
    }

    .copyright_bar ul li {
        color: #6C953C;
        font-size: 12px;
        float: left;
        margin: 0 5px 0 0;
    }
}

.copyright_bar ul li a {
    color: #6C953C;
    font-size: 12px;
    text-decoration: none;
}

.copyright_bar .social_icons {
    text-align: center;
    margin: 20px 0 0 0;
}

.copyright_bar .social_icon {
    width: 31px;
    height: 31px;
    display: inline-block;
}

.copyright_bar .social_icon img {
    width: 31px;
    height: 31px;
}

@media (min-width: 768px) {
    .copyright_bar .social_icons {
        text-align: left;
        margin: 0;
    }

    .copyright_bar .social_icon {
        float: right;
        margin: 6px 9px 0 0;
    }

    .copyright_bar .social_icon:first-child {
        margin: 6px 0 0 0;
    }
}

/* ------------ */
/* ------------ */
/* ------------ */


div.show-image {
    box-shadow: 0 1px 3px rgba(0,0,0,0.25);
}

#publisher-header {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
}
#publisher-header h2 {
    margin:0
}
#publisher-header img {
    margin-left: auto;
    padding-left: 1em;
    max-height: 80px;
    width: auto;
}

@media screen and (max-width: 992px) {
    #publisher-header {
        flex-direction: column;
        align-items: flex-start;
    }
    #publisher-header img {
        margin-left: 0;
        margin-top: 1em;
        max-height: 60px;
        padding: 0;
    }
}
