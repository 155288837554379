.sp-icon {
  width: 42px;
  height: 42px;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

@import "feature_icons";
@import "system_icons";
