/* ------------------------ */
/* -- Build Subscription -- */
/* ------------------------ */

.book-bundle-build, .book-bundle-detail {
  padding: 65px 0 53px 0;

  .bottom-border {
    border-bottom: 1px solid #DCDCDC;
  }

  .bottom-border-full {
    display: inline-block;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #DCDCDC;
    width: 100%;
  }

  .select-subject {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #CED4DA;
    background-image: url("../img/home/plus.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 42px 42px;
  }

  .icon {
    width: 44px;
    height: 44px;
    float: left;
    margin: 0 14px 0 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    float: left;
  }

  h2 {
    font-size: 30px;
    line-height: 1.875rem;
    font-weight: bold;
    color: #4A4A4A;
  }

  h3 {
    font-size: 19px;
    line-height: 1.875rem;
    font-weight: 300;
    margin: 0;
    color: #4A4A4A;
  }

  .credit {
    h3 {
      text-align: center;
      font-weight: 700;
      font-size: 18px;

      span {
        font-size: 30px;
        color: #30A64A;
      }
    }
  }

  p {
    margin: -14px 0 0 0;
    line-height: 1.875rem;
    color: #6D757D;
    font-size: 13px;
  }

  p.smallerMargin {
    margin: -7px 0 0 0;
  }

  .heading {
    display: flex;
    align-items: center;
    padding: 30px 0 17px 0;

    img {
      margin: 0 11px 0 0;
      width: 16px;
      height: 16px;
    }
  }

  .subscriptionForm {
    border-bottom: 1px solid #DCDCDC;
    padding: 35px 0;
    display: flex;
    align-items: center;
    margin: 0 0 12px 0;
  }

  .subscription_field {
    margin: 0 30px 0 0;

    label {
      font-size: 15px;
      margin: 0 0 15px 0;
    }

    .buttons {
      .btn {
        margin: 0 15px 15px 0;
      }

      .btn:last-of-type {
        margin: 0 0 15px 0;
      }
    }

    .hint {
      font-size: 13px;
      margin: 0 !important;
    }
  }

  .asset-grid, .advanced-filter {
    margin-top: 25px;
  }
}

/* ------------------------ */
/* ------------------------ */
/* ------------------------ */

/* ------------------------- */
/* -- Subscription Detail -- */
/* ------------------------- */

.subscriptionDetails {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.selectedDetail {
  display: flex;
  align-items: center;
  margin: 0 0 0 12px;

  p {
    font-size: 15px;
    font-weight: bold;
    margin: 0;
  }
}

.subscriptionLinks {
  display: flex;
  flex: 1 1 100%;
  justify-content: flex-end;
  font-size: 13px;
  color: #6C963F;
  margin: 10px 0 0 0;

  a {
    font-size: 13px;
    color: #6C963F;
  }
}

.totalSubjects {
  text-align: right;
  margin: 20px 0 0 0;

  p {
    margin: 0;
  }

  a {
    font-size: 13px;
    color: #6C963F;
  }
}

#selectSubjectModal {
  .search_bar {
    margin: 4px 0 20px 0;
    display: flex;
  }

  .subjectList {
    height: 560px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .imageSelect {
    width: 100%;
    min-height: 216px;
    margin: 0 10px 8px 0;
    display: inline-block;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    background-image: url("../img/home/book_placeholder.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.selected {
      border: 4px solid #B7D13C;
    }

    .overlay {
      background: rgba(128, 128, 128, 0.7);
    }

    .imageContent {
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px;
    }

    .imageIcon {
      width: 100%;
      height: 50%;
      background-size: 72px auto;
      background-image: url("../img/home/language_icon.png");
      background-position: center bottom;
      background-repeat: no-repeat;
    }

    .imageText {
      width: 100%;
      height: 50%;

      span {
        color: #fff !important;
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #selectSubjectModal {
    .imageSelect {
      width: calc(100% / 4 - 14px);
    }

    .imageSelect:nth-child(4n) {
      margin: 0 0 8px 0;
    }
  }
}

@media (min-width: 992px) {
  #selectSubjectModal {
    .imageSelect {
      width: calc(100% / 5 - 14px);
    }

    .imageSelect:nth-child(5n) {
      margin: 0 0 8px 0;
    }
  }
}

.subscriptionImage {
  width: 100%;
  float: left;
  margin: 0 0 10px 0;

  .show-image {
    height: 215px !important;

    .imageContent {
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-size: 72px auto;
      background-image: url("../img/home/language_icon.png");
      background-position: center;
      background-repeat: no-repeat;

      span {
        color: #fff !important;
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 768px) {
  .subscriptionImage {
    width: calc((100% / 4) - 26px);
    margin: 0 13px 25px 13px;
  }
}

@media (min-width: 992px) {
  .subscriptionImage {
    width: calc((100% / 5) - 28px);
    margin: 0 14px 28px 14px;
  }
}

@media (min-width: 1200px) {
  .subscriptionImage {
    width: calc((100% / 7) - 30px);
    margin: 0 15px 40px 15px;
  }
}

@media (min-width: 1400px) {
  .subscriptionImage {
    width: calc((100% / 7) - 30px);
    margin: 0 15px 40px 15px;
  }
}

/* ------------------------- */
/* ------------------------- */
/* ------------------------- */
