section.content, #main-container {
  background: #fff url('../img/standards/city_bg.png') no-repeat fixed 0 35%;
  background-size: cover;
}

#layout-content .city-bg {
  background: #9EBB34 url('../img/standards/city_bg.png') no-repeat 0 -70px;

  &.home-bg {
    background-size: cover;
    background-position: center center;
  }
}

section.content {
  &.get_started_bg {
    background: #fff url('../img/engage/get_started_bg.jpg') no-repeat fixed 0 35%;
    background-position: top center;
    background-size: 100%;
    padding: 100px 0 30px 0;
  }
}
