/* ------------ */
/* -- Common -- */
/* ------------ */

.city {
  h1 {
    color: #fff;
    max-width: 734px;
    padding: 40px 0 68px 0;
    margin: 0 auto;
    font-size: 37px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    text-shadow: 0 2px 3px rgba(0,0,0,0.4);

    @media (min-width: 992px) {
      padding: 92px 0 68px 0;
    }
  }

  h2 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
    color: #4A4A4A;
  }

  h3 {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0;
    color: #4A4A4A;
    margin-bottom: 23px;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    @media (min-width: 1200px) {
      max-width: 585px;
    }

    a {
      color: #B7D13C;
      text-decoration: underline;
    }

    .btn {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.5px;
      line-height: 30px;
      padding: 2px 12px;
    }
  }

  p.white_text {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: #fff;

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  .link {
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
  }

  @media (max-width: 767px) {
    .bottom_spacing {
      margin: 0 0 40px 0;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1400px) {
      height: 585px;
    }

    h1 {
      padding: 92px 0 33px 0;
    }

    .btn {
      margin: 0 0 31px 0;
    }
  }

  .header-image {
    margin: 30px 0 0 0;

    .header-content {
      margin: 70px 0;
    }

    @media (max-width: 599px) {
      iframe {
        width: 100%;
        height: auto;
      }
    }

    @media (min-width: 600px) {
      iframe {
        width: 100%;
      }
    }

    @media (min-width: 1400px) {
      margin: 0;
      align-items: center;
      display: flex;

      img {
        width: 807px;
        height: auto;
        float: left;
        margin: 0 52px 0 0;
      }

      iframe {
        width: 650px;
        height: 372px;
        float: left;
        margin: 0 48px 0 0;
      }

      .header-content {
        float: left;
        max-width: 540px;
        margin: 0;
      }
    }

    h2 {
      color: #fff;
      text-align: left;
      margin: 0 0 36px 0;
    }

    p {
      color: #fff;
      text-align: left;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 24px;
    }

    .btn {
      margin: 17px 0 0 0;
    }
  }

  .section_inner {
    @media (min-width: 1200px) {
      max-width: 585px;
    }
  }
}

.btn-white {
  background: #fff;
  color: #6C963F;
}

.engage-banner {
  height: 400px;
  background-color: #9EBB34;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;

  .container {
    height: 400px;
  }

  .row {
    height: 400px;
  }

  @media (min-width: 768px) {
    height: 300px;

    .container {
      height: 300px;
    }

    .row {
      height: 300px;
    }
  }

  @media (min-width: 992px) {
    height: 683px;

    .container {
      height: 683px;
    }

    .row {
      height: 683px;
    }
  }

  &.litpro {
    @media (min-width: 992px) {
      background-image: url("../img/engage/literacy-pro/literacy_pro_header.png");
      background-size: contain;
    }

    @media (min-width: 1400px) {
      background-size: 1284px 453px;
    }
  }

  &.promethean {
    @media (min-width: 992px) {
      background-image: url("../img/engage/promethean/promethean_header.png");
      background-size: 875px 484px;
      background-position: center 107%;
    }
  }

  .engage-banner-inner {
    display: flex;
    flex-direction: column;

    .buttons {
      position: absolute;
      bottom: 53px;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  @media (max-width: 991px) {
    background-size: contain;
  }
}

.content-padding {
  margin: 75px 0;
}

/* ------------ */
/* ------------ */
/* ------------ */

/* ------------------ */
/* -- Pricing Page -- */
/* ------------------ */

.price-block-spacing {
  @media (max-width: 1199px) {
    margin: 0 0 20px 0;
  }
}

.price-block {
  height: 100%;

  .inner-price-block {
    height: 100%;
    background: #F3F3F3;
    padding: 43px 30px 185px 28px;
    position: relative;
  }

  .sp-icon {
    width: 70px;
    height: 70px;
    display: block;
    margin: 0 auto 23px auto;
  }

  span {
    color: #909294;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    margin: 0 auto;
    display: block;

    &.description {
      padding: 0 0 43px 0;
    }
  }

  p {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    color: #4A4A4A;
  }

  ul {
    margin: 0 0 33px 0;
    padding: 0;
    list-style: none;

    li {
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 22px;
      color: #4A4A4A;
      font-family: Roboto-Light,"Helvetica Neue",Helvetica,Arial,sans-serif;
      padding: 0px 0 15px 29px;
      background: url("../img/engage/pricing/list_icon.svg") 0 3px no-repeat;

      strong {
        font-weight: bold;
      }

      a {
        color: #4A4A4A;
        text-decoration: underline;
      }

      &.green {
        color: #9FBB34;

        a {
          color: #9FBB34;
          text-decoration: underline;
        }
      }

      &:last-of-type {
        padding: 0px 0 0 29px;
      }
    }
  }

  .pricing {
    position: absolute;
    bottom: 38px;
    left: 0;
    width: 100%;
    text-align: center;

    .price {
      font-size: 1.875rem;
      line-height: 2.1875rem;
      margin: 0 0 20px 0;
      font-weight: bold;
      color: #4A4A4A;
      text-transform: uppercase;
    }

    .btn {
      margin: 20px 0 0 0;
    }
  }
}

.price-description {
  margin: 40px 0;
  text-align: center;

  p {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    max-width: none;

    a {
      color: #6C963F;
      text-decoration: none;
    }
  }
}

#clients {
  padding: 75px 0;

  h3 {
    font-size: 19px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 30px;
    color: #4A4A4A;
    text-align: center;
    margin: 0 auto 30px auto;
  }

  .client-images {
    .client {
      float: left;
      margin: 0 0 50px 0;
    }

    @media (max-width: 767px) {
      .client {
        width: 100%;
        text-align: center;

        img {
          width: 200px;
          height: auto;
        }
      }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
      .client {
        width: 100%;
        text-align: center;

        img {
          width: 300px;
          height: auto;
        }
      }
    }

    @media (min-width: 1200px) {
      float: none;
      display: flex;
      justify-content: center;
      align-items: center;

      .client {
        margin: 0 30px 0 0;

        &:last-of-type {
          margin: 0;
        }
      }

      &:nth-child(2) {
        .client {
          margin: 0 33px 0 0;

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }

    @media (min-width: 1350px) {
      .client {
        margin: 0 50px 0 0;
      }

      &:nth-child(2) {
        .client {
          margin: 0 53px 0 0;

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }

    &:first-of-type {
      margin: 0 0 40px 0;
    }
  }
}

#testimonials {
  padding: 0 0 75px 0;

  .testimonial-space {
    margin: 0 0 20px 0;

    @media (min-width: 992px) {
      margin: 0;
    }
  }

  .testimonial {
    height: 100%;
    background: #F3F3F3;
    padding: 38px 34px;
    text-align: center;

    img {
      margin: 0 auto;
      border-radius: 50%;
      border: 1px solid #979797;
      margin: 0 0 35px 0;
    }

    span {
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
      color: #909294;
      text-align: center;
      margin: 0 auto;
      display: block;
      max-width: 237px;

      &.description {
        padding: 0 0 40px 0;
      }
    }

    p {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      margin: 31px auto 0 auto;
      max-width: 315px;
    }
  }
}

/* ------------------ */
/* ------------------ */
/* ------------------ */

/* ------------------ */
/* -- Lit Pro Page -- */
/* ------------------ */

#tools {
  @media (min-width: 1200px) {
    h3 {
      max-width: 487px;
    }

    p {
      max-width: 579px;
    }
  }
  @media (min-width: 1400px) {
    img {
      height: 334px;
      width: 651px;
    }
  }
}

#reports {
  height: 600px;
  background: gray url("../img/engage/literacy-pro/literacy_pro_banner.png") no-repeat;
  background-size: cover;
  position: relative;
}

#reports .container {
  height: 600px;
}

#reports .container_inner {
  height: 600px;
}

@media (min-width: 768px) {
  #reports {
    height: 500px;
  }

  #reports .container {
    height: 500px;
  }

  #reports .container_inner {
    height: 500px;
  }
}

#literacy-pro {
  margin: 0 0 51px 0;

  img {
    width: 100%;
    height: auto;
  }

  @media (min-width: 768px) {
    img {
      width: 315px;
      height: 80px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 30px;
    color: #4A4A4A;

    @media (min-width: 768px) {
      font-size: 19px;
    }
  }
}

#books {
  @media (min-width: 1400px) {
    img {
      height: 344px;
      width: 635px;
    }
  }
}

/* ------------------ */
/* ------------------ */
/* ------------------ */

/* --------------- */
/* -- Home Page -- */
/* --------------- */

#intro {
  padding: 40px 0;
  background: #9EBB34;

  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1440px;
    }
  }
}

#devices {
  margin: 70px 0 75px 0;

  @media (min-width: 1200px) {
    h3 {
      max-width: 487px;
    }

    img {
      height: 409px;
      width: 671px;
    }
  }
}

#library {
  height: 600px;
  background: gray url("../img/engage/home/libnrary_banner.png") no-repeat;
  background-size: cover;
  position: relative;

  @media (min-width: 1200px) {
    h3 {
      max-width: 389px;
    }
  }
}

#library .container {
  height: 600px;
}

#library .container_inner {
  height: 600px;
}

@media (min-width: 768px) {
  #library {
    height: 504px;
  }

  #library .container {
    height: 504px;
  }

  #library .container_inner {
    height: 504px;
  }
}

#analytics {
  @media (min-width: 1400px) {
    img {
      height: 238px;
      width: 681px;
    }
  }
}

#teacha {
  height: 600px;
  background: gray url("../img/engage/home/education_banner.png") no-repeat;
  background-size: cover;
  position: relative;
}

#teacha .container {
  height: 600px;
}

#teacha .container_inner {
  height: 600px;
}

@media (min-width: 768px) {
  #teacha {
    height: 565px;
  }

  #teacha .container {
    height: 565px;
  }

  #teacha .container_inner {
    height: 565px;
  }
}

#education {
  margin: 75px 0 0 0;

  @media (min-width: 1400px) {
    img {
      height: 423px;
      width: auto;
    }
  }
}

#support {
  height: 600px;
  background: gray url("../img/engage/home/support_banner.png") no-repeat;
  background-size: cover;
  position: relative;

  @media (min-width: 1200px) {
    h3 {
      max-width: 450px;
    }
  }
}

#support .container {
  height: 600px;
}

#support .container_inner {
  height: 600px;
}

@media (min-width: 768px) {
  #support {
    height: 483px;
  }

  #support .container {
    height: 483px;
  }

  #support .container_inner {
    height: 483px;
  }
}

#partners {
  padding: 75px 0;

  h3 {
    font-size: 19px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 30px;
    color: #4A4A4A;
    text-align: center;
    margin: 0 auto 42px auto;
  }

  .partner-images {
    .partner {
      float: left;
      margin: 0 0 50px 0;
    }

    @media (max-width: 767px) {
      .partner {
        width: 100%;
        text-align: center;

        img {
          width: 200px;
          height: auto;
        }
      }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
      .partner {
        width: 100%;
        text-align: center;

        img {
          width: 300px;
          height: auto;
        }
      }
    }

    @media (min-width: 1200px) {
      float: none;
      display: flex;
      justify-content: center;
      align-items: center;

      .partner {
        margin: 0 20px 0 0;

        &:last-of-type {
          margin: 0;
        }
      }
    }

    @media (min-width: 1350px) {
      .partner {
        margin: 0 43px 0 0;
      }
    }
  }
}

/* --------------- */
/* --------------- */
/* --------------- */

/* --------------------- */
/* -- Promethean Page -- */
/* --------------------- */

#empower {
  @media (min-width: 1200px) {
    h3 {
      max-width: 487px;
    }
  }

  @media (min-width: 1400px) {
    img {
      height: 334px;
      width: 579px;
    }
  }
}

#boards {
  height: 600px;
  background: gray url("../img/engage/promethean/promethean_banner.png") no-repeat;
  background-size: cover;
  position: relative;
}

#boards .container {
  height: 600px;
}

#boards .container_inner {
  height: 600px;
}

@media (min-width: 768px) {
  #boards {
    height: 500px;
  }

  #boards .container {
    height: 500px;
  }

  #boards .container_inner {
    height: 500px;
  }
}

#classroom {
  margin: 59px 0 73px 0;

  @media (min-width: 1400px) {
    img {
      height: 306px;
      width: 585px;
    }
  }
}

#promethean {
  margin: 0 0 48px 0;

  img {
    width: 100%;
    height: auto;
  }

  @media (min-width: 768px) {
    img {
      height: 41px;
      width: 275px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 30px;
    color: #4A4A4A;

    @media (min-width: 768px) {
      font-size: 19px;
    }
  }
}


/* --------------------- */
/* --------------------- */
/* --------------------- */
