.btn {
  white-space: nowrap;
}

.btn-primary, .btn-info, .btn-success {
  color: #FFFFFF;
}

.btn-primary:hover, .btn-outline-primary:hover, btn-info:hover, .btn-success:hover {
  color: #FFFFFF;
}

.btn-outline-secondary {
  border: none;
  outline: none;
  background: none;

  &:hover {
    background: none;
    border: none;
    color: #58595b;
    outline: none;
  }
}

.btn-outline-success {
  border-color: #6C943E;

  &:hover {
    background: #6C943E;
    border-color: #6C943E;
    color: #fff;
  }
}

.btn-outline-warning {
  border-color: #FFC107;

  &:hover {
    background: #FFC107;
    border-color: #FFC107;
    color: #fff;
  }
}

.btn-outline-info {
  border-color: #3E92CC;

  &:hover {
    background: #3E92CC;
    border-color: #3E92CC;
    color:#fff;
  }
}

.btn-outline-dark {
  border-color: #222628;

  &:hover {
    background: #222628;
    border-color: #222628;
    color:#fff;
  }
}

.btn-learn-more {
  color: #b7c73b;
  outline: none;
}

.btn-learn-more:hover {
  color: #9dab31;
  text-decoration: none;
  outline: none;
}

.btn-sign-in, .btn-invite-code, .btn-share {
  background: #fff;
  color: #343434;
  border: 1px solid #cccccc;
  text-transform: uppercase;
  width: 100%;
  max-width: 460px;
  height: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.btn-sign-in:hover, .btn-share:hover {
  box-shadow: inset -2px -2px 5px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  border-color: #cccccc;
}

.btn-sign-in .fa, .btn-share .fa {
  margin: 0 5px 0 0;
}

.btn-sign-in img {
  width: 100%;
  height: auto;
}

.shareLinks .dropdown-menu {
  padding: 0;
}

.shareLinks .dropdown-item {
  display: flex;
  padding: 0.75rem 1.5rem;
  align-items: center;
}

.shareLinks .share-item {
  margin: 0 20px 0 0;
}

.shareLinks .share-item:last-of-type {
  margin: 0;
}

.shareLinks .share-item .fa {
  width: 64px;
  height: 64px;
  font-size: 52px;
}

.btn-outline-danger {
  color: #dc3545;
  border: 1px solid #dc3545;
}

.btn-snapplify .badge {
  color: #CEE279;
  background-color: #FFFFFF;
}

.btn-full {
  width: 100%;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;

  &:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    text-decoration: none !important;
  }
}
