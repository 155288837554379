.drop-down {
  position: relative;
  width: 100%;
  border: 2px solid #B6CF3A;
  color: #4A4A4A;
  padding: 10px 20px 10px 20px;
  background: #FFFFFF;
}

.drop-down .button {
  background: url('../img/standards/select_box_arrow.svg') no-repeat right center;
}

.drop-down .button span {
  display: block;
  background-color: transparent;
  background-size: 25px 24px;
  background-repeat: no-repeat;
  background-position: left center;
  padding: 0 0 0 35px;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 25px;
  text-overflow: ellipsis;
}

.drop-down .button a {
  display: block;
  text-indent: -9999px;
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.drop-down select {
  display: none;
}

.drop-down .select-list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-top: 40px;
  padding: 0;
  background-color: #fff;
  height: 300px;
  display: none;
  overflow-y: scroll;
}

.drop-down .select-list li {
  display: none;
}

.drop-down .select-list li span {
  display: inline-block;
  min-height: 40px;
  width: 100%;
  color: #24262A;
  padding: 10px 15px 10px 45px;
  background-color: white;
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 25px 24px;
  text-align: left;
  box-sizing: border-box;
  cursor: default;
}

.drop-down .select-list li span:hover {
  cursor: pointer;
  background-color: #e8e8e8;
  color: #111;
}

@media (min-width: 1200px) {
  .drop-down {
    width: 267px;
    border: 3px solid #B6CF3A;
    padding: 10px 23px 10px 23px;
    margin: 0 30px 0 0;
  }
}
