.datepicker {
  padding: 4px;
  border-radius: 4px;
  color: #222;

  &-inline {
    width: 220px;
  }

  direction: ltr;

  &.datepicker-rtl {
    direction: rtl;

    table tr td span {
      float: right;
    }
  }

  &-dropdown {
    top: 0;
    left: 0;

    &.datepicker-orient-left:before {
      left: 5px;
    }

    &.datepicker-orient-left:after {
      left: 6px;
    }

    &.datepicker-orient-right:before {
      right: 5px;
    }

    &.datepicker-orient-right:after {
      right: 6px;
    }

    &.datepicker-orient-top:before {
      top: -6px;
    }

    &.datepicker-orient-top:after {
      top: -5px;
    }

    &.datepicker-orient-bottom:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid #999;
    }

    &.datepicker-orient-bottom:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #fff;
    }
  }

  &.days .datepicker-days,
  &.months .datepicker-months,
  &.years .datepicker-years {
    display: block;
  }

  table {
    margin: 5px 10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    > tbody,
    > thead {
      > tr {
        > td,
        > th {
          text-align: center;
          width: 30px;
          height: 30px;
          border-radius: 4px;

          border: none;
        }
      }
    }
  }

  // Inline display inside a table presents some problems with
  // border and background colors.
  .table-striped & table tr {
    td, th {
      background-color: transparent;
    }
  }

  table tr td {
    &.day:hover, &.day.focused {
      background: #f5f5f5;
      cursor: pointer;
    }

    &.old,
    &.new {
      color: #aaa;
    }

    &.disabled,
    &.disabled:hover {
      background: none;
      color: #aaa;
      cursor: default;
    }

    &.today:hover:hover { // Thank bootstrap 2.0 for this selector...
      // TODO: Bump min BS to 2.1, use @textColor in buttonBackground above
      color: #000;
    }

    &.today.active:hover {
      color: #fff;
    }

    &.range,
    &.range:hover,
    &.range.disabled,
    &.range.disabled:hover {
      background: #f5f5f5;
      border-radius: 0;
    }

    span {
      display: block;
      width: 23%;
      height: 54px;
      line-height: 54px;
      float: left;
      margin: 1%;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background: #f5f5f5;
      }

      &.disabled,
      &.disabled:hover {
        background: none;
        color: #aaa;
        cursor: default;
      }

      &.old,
      &.new {
        color: #aaa;
      }
    }
  }

  .datepicker-switch {
    width: 145px;
  }

  thead tr:first-child th,
  tfoot tr th {
    cursor: pointer;

    &:hover {
      background: #f5f5f5;
    }
  }

  // Basic styling for calendar-week cells
  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }

  thead tr:first-child .cw {
    cursor: default;
    background-color: transparent;
  }
}
