.modal-dialog {
  &.leaveGroup {
    text-align: center;
    max-width: 300px;

    .modal-body {
      padding: 16px 16px 24px 16px;
    }

    img {
      margin: 8px 0 16px 0;
    }

    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      color: #222628;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      color: #767676;
    }

    .btn-outline-danger {
      margin: 0 16px 0 0;
    }
  }
}
