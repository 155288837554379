$font-family-sans-serif: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$link-color: #1997c6;
$link-hover-color: #106382;
$primary: #b7c73b !default;
$secondary: #58595b !default;
$breadcrumb-border-radius: 0.25rem;
$breadcrumb-bg: #ffffff;
$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 0.5rem;
$info: #17a2b8 !default;
$success: #b7c73b !default;
$grid-gutter-width: 30px;
$table-cell-padding-y: .75rem;
$table-cell-padding-x: .75rem;
$badge-padding-y: .25em .6em;
$badge-padding-x: .25em .6em;
$badge-border-radius: .25rem !important;
$card-cap-padding-y: .75rem;
$card-cap-padding-x: 1.25rem;
$small-font-size: 80%;
$table-hover-bg-factor: .00;
