h3 img {
    width: 42px;
    height: 42px;
    margin: -10px 10px 0px 10px;
}

.event_wizard {
    width: 100%;
}

.content .steps {
    float: left;
    list-style: none !important;
    margin: 0;
    padding: 0;
    width: 30px;
    position: relative;
    border: none;
}

.steps li {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #999;
    text-align: center;
    border-radius: 50%;
    margin: 0 0 20px 0;
    z-index: 10;
}

.steps li:last-of-type {
    margin: 0;
}

.steps li.line {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin: 0 0 0 -1px;
    background: #999;
    z-index: 1;
}

.steps li a {
    color: #fff;
}

.wizard_form {
    float: left;
    margin: 0 0 0 20px;
    width: calc(100% - 50px);
}

.persona-block {
    background-color: #f2f2f2;
    padding: 25px 25px;
    text-align: center;
    position: relative;
}

.persona-block a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}